import React, { useContext, useEffect, useState } from 'react';
import { FirebaseContext } from '../Firebase';

const App = () => {
  const { db } = useContext(FirebaseContext);
  const [foo, setFoo] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await db.collection('foo').get();
        querySnapshot.forEach((doc) => {
          setFoo(doc.data().bar);
        });
      } catch (e) {
        throw new Error(e)
      }
    }
    fetchData();
  }, []);

  return (<p>{foo}!</p>)
};

export { App };
