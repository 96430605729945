import React, { createContext } from 'react';
import { Firebase } from './firebaseApp';

const FirebaseContext = createContext(null);
const firebaseApp = new Firebase();

const FirebaseProvider = ({ children }) => {
  return (
    <FirebaseContext.Provider value={firebaseApp}>
      {children}
    </FirebaseContext.Provider>
  )
}

export { FirebaseContext, FirebaseProvider };
